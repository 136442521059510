import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import Modal from 'react-modal';
import download from 'downloadjs';
import FileDetailsCreateOrder from '../file/FileDetailsCreateOrder';
import FileTaskCreateOrder from '../file/FileTaskCreateOrder';
import OrderSummary from '../misc/OrderSummary';
import AdditionalInfoCO from '../misc/AdditionalInfoCO';
import ErrorNotice from '../misc/ErrorNotice';
import { addWeekDays } from '../misc/Util';
import { useHistory } from 'react-router-dom';
import UserContext from '../../context/UserContext';
import ControlContext from '../../context/ControlContext';
import MethodContext from '../../context/MethodContext';
import ReactTooltip from 'react-tooltip';
import InfoCreateOrder from '../misc/InfoCreateOrder';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const CreateOrder = () => {
    const history = useHistory();
    const { userData } = useContext(UserContext);
    const { methodDefs } = useContext(MethodContext);
    const { control, setControl } = useContext(ControlContext);
    const [error, setError] = useState();
    const [offer, setOffer] = useState();

    const [deliverySelected, setDeliverySelected] = useState(false);
    const [orderParamsComplete, setOrderParamsComplete] = useState(false);
    const [summary, setSummary] = useState({
        netto: 0,
        surcharge: 0,
        mwst: 0,
        delivery: 0,
        brutto: 0,
        totalTime: 0,
        savings: 0,
    });
    const [deliveryPrice, setDeliveryPrice] = useState(0.0);
    const [numPackages, setNumPackages] = useState(0);
    const [totalWeight, setTotalWeight] = useState(0);
    const [delivery, setDelivery] = useState(-1);
    const [vat, setVat] = useState(0);

    const [projectID, setProjectID] = useState('');
    const [inquiryID, setInquiryID] = useState('');
    const [orderID, setOrderID] = useState('');
    const [comment, setComment] = useState('');

    const [cb1, setCb1] = useState(false);
    const [cb2, setCb2] = useState(false);
    const [approved, setApproved] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [modalLabel, setModalLabel] = useState('AGB');
    const [modalText, setModalText] = useState('Lorem Ipsum');

    const [orderExecuted, setOrderExecuted] = useState(false);

    const [paymentMethods, setPaymentMethods] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState('payBefore');

    const modalClose = () => {
        setShowModal(false);
    };

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    const checkApproved = () => {
        let apprvd = true;

        offer?.items?.forEach(function (item) {
            if (!item.approved) apprvd = false;
        });

        offer?.tasks?.forEach(function (task) {
            if (!task.approved) apprvd = false;
        });

        offer?.items?.forEach(function (item) {
            item.tasks?.forEach(function (task) {
                if (!task.approved || task.approved === false) {
                    apprvd = false;
                }
            });
        });

        setApproved(apprvd);
    };

    const FinishOrder = async () => {
        try {
            setOrderExecuted(true);
            let order = {};
            order.projectID = projectID || '';
            order.inquiryID = inquiryID || '';
            order.comment = offer.comment || '';
            order.user_orderID = orderID;
            order.deliveryAdress = control.deliveryAdress;
            if (control.invoiceAdress) {
                order.invoiceAdress = control.invoiceAdress;
            } else {
                order.invoiceAdress = control.deliveryAdress;
            }
            order.files = [];
            order.summary = summary;
            order.deliveryMethod = control.deliveryMethod;
            order.fromOffer = control.viewOffer;
            order.fromOfferIDS = offer.IDS;

            order.documents = offer.documents;

            order.paymentMethod = paymentMethods.find((el) => el._id === paymentMethod);

            offer.items.forEach((file) => {
                let uploadFile = {};
                uploadFile.prices = file.prices;
                uploadFile._id = file._id;
                uploadFile.tasks = file.tasks;
                console.log(file.tasks);
                uploadFile.comment = file.comment;
                uploadFile.filePath = file.filePath;
                order.files.push(uploadFile);
            });
            order.tasks = offer.tasks;
            let token = localStorage.getItem('auth-token');

            if (userData.user.mustPayBefore === true) {
                const os = methodDefs.orderStati.find((element) => element.name === 'os_wait_payment');
                order.status = os._id;
            } else {
                const os = methodDefs.orderStati.find((element) => element.name === 'os_incoming');
                order.status = os._id;
            }

            const res = await Axios.post(`${process.env.REACT_APP_SERVER}/files/saveOrder`, order, { headers: { 'x-auth-token': token } });
            setControl({
                ...control,
                lastOrder: res.data.orderID,
                lastOrderNumber: res.data.orderNumber,
                lastOrderIDS: res.data.orderIDS,
                paymentMethod: order.paymentMethod,
            });

            history.push('/thanks');
        } catch (err) {
            console.log(err);
        }
    };

    const ToOrder = () => {
        FinishOrder();
    };

    const ToOrderDisabled = () => {
        let msg = '';
        if (!approved) {
            msg += 'Die Bestellung enthält mindestens eine Leistung, die noch nicht durch einen Mitarbeiter frei gegeben wurde.\n\n';
        }
        if (!deliverySelected) {
            msg += 'Bitte wählen Sie eine Lieferart\n';
        }
        if (!cb1) {
            msg += 'Bitte bestätigen Sie, dass Sie die AGB und Datenschutzerklärung akzeptieren.\n';
        }
        if (!cb1) {
            msg += 'Bitte bestätigen Sie, dass Sie die Widerrufsbelehrung gelesen haben.\n';
        }
        confirmAlert({
            message: msg,
            buttons: [
                {
                    label: 'OK',
                    onClick: () => {},
                },
            ],
        });
    };

    const ToOfferPage = () => {
        setControl({
            ...control,
            viewOffer: offer._id,
        });
        history.push('/viewOffer');
    };

    const updateProductionTime = async () => {
        try {
            let files = [];

            offer.items.forEach((file) => {
                let uFile = {};
                uFile._id = file._id;
                const pr = file.prices.find((o) => o.selected === true);
                uFile.amount = parseInt(pr.amount);
                uFile.method = file.method._id;
                uFile.material = file.material._id;
                uFile.processing = file.processing?._id;
                uFile.color = file.color?._id;
                uFile.x = file.boundingBox[0];
                uFile.y = file.boundingBox[1];
                uFile.z = file.boundingBox[2];
                uFile.w = file.boundingBox[0];
                uFile.h = file.boundingBox[1];
                uFile.d = file.boundingBox[2];
                uFile.area = file.area;
                uFile.volume = file.volume;
                uFile.tasks = file.tasks;
                files.push(uFile);
            });
            let token = localStorage.getItem('auth-token');
            const res = await Axios.post(`${process.env.REACT_APP_SERVER}/files/productiontime`, files, { headers: { 'x-auth-token': token } });
            if (res && res.data && res.data.time) {
                let days = res.data.time;
                offer.tasks?.forEach((task) => {
                    task.prices.forEach((pr) => {
                        if (pr.selected) days += parseInt(pr.time);
                    });
                });
                return days;
            }
            return 0;
        } catch (err) {
            console.log(err);
            return 0;
        }
    };

    const paramsComplete = async () => {
        if (offer) {
            let summ = {
                netto: 0,
                surcharge: 0,
                mwst: 0,
                delivery: 0,
                brutto: 0,
                totalTime: 0,
                savings: 0,
                vat: vat,
            };
            let complete = true;
            offer?.items?.forEach((file) => {
                //complete &= file.orderParamsComplete;
                if (file.selectedPrice) {
                    const ep = parseFloat(file.selectedPrice.price) ? parseFloat(file.selectedPrice.price) : 0.0;
                    summ.netto += ep * parseInt(file.selectedPrice.amount);
                    summ.delivery += parseFloat(file.selectedPrice.deliveryPrice);
                    summ.savings += parseFloat(file.selectedPrice.savings);
                }

                //complete &= file.orderParamsComplete;
                file.tasks?.forEach((task) => {
                    if (task.selectedPrice) {
                        const ep = parseFloat(task.selectedPrice.price) ? parseFloat(task.selectedPrice.price) : 0.0;
                        summ.netto += ep * parseInt(task.selectedPrice.amount);
                    }
                });
            });

            offer?.tasks?.forEach((task) => {
                //complete &= file.orderParamsComplete;
                if (task.selectedPrice) {
                    const ep = parseFloat(task.selectedPrice.price) ? parseFloat(task.selectedPrice.price) : 0.0;
                    summ.netto += ep * parseInt(task.selectedPrice.amount);
                }
            });

            updateDeliveryPrice(delivery);
            summ.delivery = parseFloat(deliveryPrice);
            summ.numPackages = numPackages;
            summ.surcharge = Math.max(0, userData.user.minOrderValue - summ.netto);
            summ.totalWeight = totalWeight;
            summ.mwst = (summ.netto + summ.surcharge + summ.delivery) * vat;
            summ.brutto = summ.netto + summ.surcharge + summ.delivery + summ.mwst;
            setOrderParamsComplete(complete);
            if (complete) {
                summ.totalTime = await updateProductionTime();
                let dd = new Date();
                addWeekDays(dd, summ.totalTime);
                const month = (dd.getUTCMonth() + 1).toString().padStart(2, '0'); //months from 1-12
                const day = dd.getUTCDate().toString().padStart(2, '0');
                const year = dd.getUTCFullYear();
                summ.sendDate = `${day}.${month}.${year}`;
            }
            //console.log(`params complete: ${summ.brutto}`);
            setSummary(summ);

            checkApproved();
        }
    };

    const deleteFile = (_id) => {
        if (offer.items.length > 1) {
            let items = offer.items.filter(function (obj) {
                return obj._id !== _id;
            });
            setOffer({ ...offer, items: items });

            checkApproved();
        }
    };

    useEffect(() => {
        if (control.maintenance) {
            history.push('/maintenance');
        }
        const checkLoggedIn = async () => {
            let token = localStorage.getItem('auth-token');
            if (token === null) {
                localStorage.setItem('auth-token', '');
                token = '';
                history.push('/login');
            }

            const tokenRes = await Axios.post(`${process.env.REACT_APP_SERVER}/users/tokenIsValid`, null, { headers: { 'x-auth-token': token } });

            if (!tokenRes.data) {
                history.push('/login');
            } else {
                try {
                    const res = await Axios.post(
                        `${process.env.REACT_APP_SERVER}/files/offer`,
                        { _id: control.viewOffer },
                        { headers: { 'x-auth-token': token } }
                    );
                    if (res.data.items) {
                        res.data.items.forEach((item) => {
                            item.prices.forEach((pr) => {
                                pr.selected = false;
                            });
                            item.prices[0].selected = true;
                            item.selectedPrice = item.prices[0];
                        });
                    }
                    if (res.data.tasks) {
                        res.data.tasks.forEach((task) => {
                            task.prices.forEach((pr) => {
                                pr.selected = false;
                            });
                            task.prices[0].selected = true;
                            task.selectedPrice = task.prices[0];
                        });
                    }

                    setOffer(res.data);
                    setProjectID(res.data.projectID);
                    setInquiryID(res.data.inquiryID);
                    setComment(res.data.comment);

                    checkApproved();
                } catch (err) {
                    console.log(err);
                    setError(err.message);
                }
            }
            try {
                if (userData.user.mustPayBefore === true) {
                    setPaymentMethods(
                        methodDefs.payments.filter((el) => {
                            return el.isBefore === true;
                        })
                    );
                    setPaymentMethod(methodDefs.payments.find((el) => el.isBefore === true)._id);
                } else {
                    setPaymentMethods(methodDefs.payments);
                    setPaymentMethod(methodDefs.payments.find((el) => el.isBefore === false)._id);
                }
            } catch (err) {
                console.log(err);
            }
        };

        checkLoggedIn();
    }, [history]);

    const deleteTask = (task) => {
        let newTasks = offer.tasks.filter(function (obj) {
            return obj.uuid !== task.uuid;
        });
        setOffer({ ...offer, tasks: newTasks });

        checkApproved();
    };

    const updateTask = (task) => {
        let newTasks = [...offer.tasks];
        newTasks.forEach(function (item, i) {
            if (item.uuid === task.uuid) newTasks[i] = task;
        });
        setOffer({ ...offer, tasks: newTasks });
    };

    const updateDeliveryPrice = async (id) => {
        //console.log(`updateDeliveryPrice ${delivery}`);
        if (!id || id === '-1' || id === -1) {
            setDeliveryPrice(0.0);
            setDeliverySelected(false);
        } else {
            try {
                let token = localStorage.getItem('auth-token');

                let params = [];
                offer?.items?.forEach((item) => {
                    let it = {};
                    it.weight = item.weight;
                    it.amount = item.selectedPrice?.amount || 0;
                    it.x = item.boundingBox[0];
                    it.y = item.boundingBox[1];
                    it.z = item.boundingBox[2];
                    params.push(it);
                });

                const res = await Axios.post(
                    `${process.env.REACT_APP_SERVER}/files/deliveryprice`,
                    {
                        settings: { deliveryID: id, netto: summary.netto },
                        params: params,
                    },
                    { headers: { 'x-auth-token': token } }
                );
                if (res.data.valid === true) {
                    setNumPackages(res.data.numPackages);
                    setTotalWeight(res.data.totalWeight);
                    setDeliveryPrice(res.data.price);
                    setDeliverySelected(true);
                } else {
                    setDeliveryPrice(0.0);
                    setDeliverySelected(false);
                }
            } catch (err) {
                err?.response?.data?.msg && setError(err?.response?.data?.msg);
            }
        }
    };

    useEffect(() => {
        //console.log(`useEffect delivery ${delivery}`);
        updateDeliveryPrice(delivery);
    }, [delivery]);

    useEffect(() => {
        paramsComplete();
    }, [deliveryPrice, vat, offer]);

    /*
    useEffect(() => {
        paramsComplete();
    }, [vat]);

    useEffect(() => {
        paramsComplete();
    }, [offer]);
*/
    const openModal = async (mode) => {
        try {
            let token = localStorage.getItem('auth-token');
            const res = await Axios.get(`${process.env.REACT_APP_SERVER}/admin/settings/`, { headers: { 'x-auth-token': token } });
            if (res) {
                if (mode === 'agb') {
                    setModalText(res.data.agb);
                } else if (mode === 'datenschutz') {
                    setModalText(res.data.datenschutz);
                } else if (mode === 'widerruf') {
                    setModalText(res.data.widerruf);
                }
                setShowModal(true);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const onAgb = () => {
        setModalLabel('AGB');
        openModal('agb');
    };

    const onDatenschutz = () => {
        setModalLabel('Datenschutz');
        openModal('datenschutz');
    };

    const onWiderruf = () => {
        setModalLabel('Widerruf');
        openModal('widerruf');
    };

    const onPaymentSelect = (e) => {
        console.log(`onPaymentSelect:CreateOrder.js:388 ${e}`);
        setPaymentMethod(e);
    };

    const ToDownloadAdminXML = () => {
        try {
            let token = localStorage.getItem('auth-token');
            const config = {
                responseType: 'blob',
                headers: { 'x-auth-token': token, 'Content-type': 'text/xml; charset=utf-8' },
                params: {
                    delmeth: control.deliveryMethod,
                    surcharge: summary.surcharge,
                    deliveryPrice: summary.delivery,
                    numPackages: summary.numPackages,
                    totalWeight: summary.totalWeight,
                    vat: vat,
                },
            };
            const fileName = `angebotsitems_${offer.IDS}.xml`;

            Axios.get(`${process.env.REACT_APP_SERVER}/admin/offeritems`, config).then((res) => {
                download(res.data, fileName);
            });
        } catch (err) {
            console.log(err);
            err.response && err.response.data && err.response.data.msg && setError(err.response.data.msg);
        }
    };

    return (
        <div>
            <div className="container">
                
                    <div id="info-area">
                        <InfoCreateOrder />
                        {error && <ErrorNotice message={error} clearError={() => setError(undefined)} />}
                    </div>
               
            </div>

            <Modal isOpen={showModal} onRequestClose={(e) => modalClose(e)} contentLabel={modalLabel} style={customStyles}>
                <div className="modalHeader">
                    <div>
                        <h2>{modalLabel}</h2>
                    </div>
                    <div>
                        <button id="buttonX" onClick={() => modalClose()}>
                            &#10006;
                        </button>
                    </div>
                </div>
                <div className="modalBody" dangerouslySetInnerHTML={{ __html: modalText }}></div>
            </Modal>

            {offer && (
                <AdditionalInfoCO
                    readOnly={false}
                    projectID={projectID}
                    inquiryID={inquiryID}
                    comment={comment}
                    orderID={orderID}
                    setOrderID={setOrderID}
                    status=""
                />
            )}
            <div id="file-list">
                {offer &&
                    offer.items.length > 0 &&
                    offer.items.map((item, pos) => (
                        
                            <FileDetailsCreateOrder key={item._id} item={item} paramsComplete={paramsComplete} deleteFile={deleteFile} position={pos} />
                        
                    ))}
            
                {offer &&
                    offer.tasks?.length > 0 &&
                    offer.tasks.map((task, pos) => (
                        <FileTaskCreateOrder
                            key={task.uuid}
                            position={offer.items.length + pos + 1}
                            task={task}
                            paramsComplete={paramsComplete}
                            updateTask={updateTask}
                            deleteTask={deleteTask}
                        />
                    ))}
            
            </div>

            <div id="footer-container">
                <div id="footer-inner-container">
                    <OrderSummary
                        summary={summary}
                        deliverySelected={deliverySelected}
                        setDeliverySelected={setDeliverySelected}
                        delivery={delivery}
                        setDelivery={setDelivery}
                        setVat={setVat}
                        payment={paymentMethod}
                        setPayment={onPaymentSelect}
                        paymentMethods={paymentMethods}
                    />

                    {!approved ? (
                        <>
                            <div className="user-hint">
                                Zu diesem Angebot sind noch Zuarbeiten von Dick&Dick nötig, bevor Sie einen Auftrag auslösen können.
                            </div>
                        </>
                    ) : (
                        <>
                            {!orderParamsComplete || !deliverySelected ? (
                                <>
                                    <div className="user-hint">
                                        <div>
                                            {!orderParamsComplete ? (
                                                <>
                                                    <label>Bitte vervollständigen Sie die Mengenauswahl.</label>
                                                </>
                                            ) : null}
                                            {!deliverySelected ? <label>Bitte wählen Sie eine Versandart.</label> : null}
                                        </div>
                                    </div>
                                </>
                            ) : null}
                        </>
                    )}

                    <div id="create-order-button-footer">
                        <div id="legal-select-box">
                            <div className="legal-item">
                                <input type="checkbox" id="cb100001" checked={cb1} onChange={(e) => setCb1(e.target.checked)} />
                                &nbsp;
                                <label htmlFor="cb100001">Bitte bestätigen Sie, dass sie unsere&nbsp;</label>
                                <button className="link-white" onClick={onAgb}>
                                    AGB
                                </button>
                                &nbsp;und&nbsp;{' '}
                                <button className="link-white" onClick={onDatenschutz}>
                                    Datenschutzerklärung
                                </button>
                                &nbsp;gelesen haben.
                            </div>
                            <div className="legal-item">
                                <input type="checkbox" id="cb100002" checked={cb2} onChange={(e) => setCb2(e.target.checked)} />
                                &nbsp;<label htmlFor="cb100002">Bestätigung der&nbsp;</label>
                                <button className="link-white" onClick={onWiderruf}>
                                    Widerrufsbelehrung
                                </button>
                            </div>
                        </div>

                        <div id="button-container">
                            <button className="page-button-white" onClick={ToOfferPage}>
                                Zurück zum Angebot
                            </button>
                            {(!orderParamsComplete || !deliverySelected || !cb1 || !cb2 || !approved) && !orderExecuted ? (
                                <>
                                    <button className="page-button page-button-disabled" onClick={ToOrderDisabled} data-tip data-for="tt6">
                                        Zahlungspflichtig bestellen
                                    </button>
                                    <ReactTooltip id="tt6" place="top" effect="solid">
                                        Bitte alle benötigten Felder auswählen.
                                    </ReactTooltip>
                                </>
                            ) : (
                                <>
                                    <button className="page-button-action" onClick={ToOrder}>
                                        Zahlungspflichtig bestellen
                                    </button>
                                </>
                            )}
                            {orderParamsComplete && deliverySelected && !orderExecuted && userData?.rights?.includes('admin') ? (
                                <>
                                    <button className="page-button-white" onClick={ToDownloadAdminXML}>
                                        Download XML
                                    </button>
                                </>
                            ) : null}
                        </div>
                    </div>
                    {summary.surcharge > 0 ? (
                        <>
                            <div className="wrapper_1500">
                                <label className="minValueLabel">
                                    *Ihr Bestellwert liegt unter dem Mindestauftragswert von {userData.user.minOrderValue}€, daher ergibt sich bei
                                    dieser Bestellung ein Mindermengenzuschlag.
                                </label>
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default CreateOrder;
